/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import Filters from '../../components/FNFilters';
import CoverageDistrict from './coveragedistrict';
import CoverageOwnership from './coverageOwnership';
import CoverageLevel from './coveragelevel';
import CoverageRegion from './coverageRegion';
import CoverageReg from './coverageReg';

const Unmc = () => {

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [enrolled, setEnrolled] = useState(0);
    const [registered, setRegistered] = useState(0);
    const [licensed, setLicensed] = useState(0);
    const [training, setTraining] = useState(0);
    const [activeTab, setActiveTab] = useState('enrolled');
    const [percentage, setPercentage] = useState();

    const loadStats = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/nurses/total`);
            setEnrolled(res?.data.enrolled);
            setRegistered(res?.data.registered);
            setLicensed(res?.data.licensed);
            setTraining(res?.data.training);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };



    useEffect(() => {
        loadStats();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'enrolled':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of facilities assessed by District </h4>
                                    <CoverageDistrict />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">CoverageDistrict Rate of Midwives & Nurses</h4>
                                    <EnrollmentRate />
                                </div>
                            </div>
                        </div> */}
                    </div>
                );
            case 'registered':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of facilities assessed by Authority</h4>
                                    <CoverageOwnership />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'licensed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of facilities assessed by Level of Care</h4>
                                    <CoverageLevel />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'deployed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Facilities Assessed by Region</h4>
                                    <CoverageRegion />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'cadre':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Number of Health Facilities Assessed by Ownership</h4>
                                    <CoverageReg />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'training':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Comparison of overall districts performance in 2021, 2022 and 2023</h4>
                                    {/* <Training /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Health Facility Quality Assessment Dashboard (HFQAP) - Public Facilities</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">5 Star (0 %)</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">0</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">4 Star (21 %)</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">20</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">3 Star (40 %)</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">50</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-primary">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">2 Star (28 %)</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">23</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">1 Star (3.75 %)</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">3</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-all">
                        <div class="card-body">
                            <h5 class="text-muted fw-medium">ALL</h5>
                            <div class="star_widget">
                                <small><i class="fa fa-star font9"></i></small>
                            </div>
                            <h6 class="m-0 font10"><strong data-plugin="counterup">90</strong> Facilities </h6>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'enrolled' ? 'active' : ''}`}
                        onClick={() => setActiveTab('enrolled')}
                        role="tab"
                        aria-selected={activeTab === 'enrolled'}
                    >
                        Coverage by District
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'registered' ? 'active' : ''}`}
                        onClick={() => setActiveTab('registered')}
                        role="tab"
                        aria-selected={activeTab === 'registered'}
                    >
                        Coverage by Authority 
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'licensed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('licensed')}
                        role="tab"
                        aria-selected={activeTab === 'licensed'}
                    >
                        Coverage by Level of Care
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'deployed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('deployed')}
                        role="tab"
                        aria-selected={activeTab === 'deployed'}
                    >
                        Coverage By Region
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'cadre' ? 'active' : ''}`}
                        onClick={() => setActiveTab('cadre')}
                        role="tab"
                        aria-selected={activeTab === 'cadre'}
                    >
                        Coverage By Ownership
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'training' ? 'active' : ''}`}
                        onClick={() => setActiveTab('training')}
                        role="tab"
                        aria-selected={activeTab === 'training'}
                    >
                        Progressive Trends
                    </a>
                </li>
            </ul>
            <div className="tab-content p-3">
                <div className={`tab-pane ${activeTab === 'enrolled' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'enrolled' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'registered' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'registered' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'licensed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'licensed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'deployed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'deployed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'cadre' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'cadre' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'training' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'training' && renderContent()}
                </div>
            </div>
        </Fragment >
    )
}

export default Unmc