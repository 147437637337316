import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import API from "../../../helpers/api";
import Graphs from './Graphs';

const Performance = () => {
    const [year, setYear] = useState('');
    const [district, setDistrict] = useState(null);
    const [county, setCounty] = useState(null);
    const [facility, setFacility] = useState(null);
    const [data, setData] = useState([]);
    const [graphs, setGraphs] = useState([]);
    const [error, setError] = useState(null);
    const [columns, setColumns] = useState([]);
    const [facilityType, setFacilityType] = useState('');
    const [activeTab, setActiveTab] = useState('data');

    const [districts, setDistricts] = useState([]);
    const [subCounties, setSubCounties] = useState([]);
    const [facilities, setFacilities] = useState([]);

    const [gLoading, setGLoading] = useState(false);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === 'data') {
            fetchData();
        } else if (tab === 'graphs') {
            graphsData();
        }
    };

    const clearFilters = () => {
        setYear("");
        setDistrict(null);
        setCounty(null);
        setFacility(null);
        setData([]);
        setFacilityType("");
    };

    const graphsData = async () => {
        setGLoading(true)
        try {
            const response = await API.get('/graphs', {
                params: { type: facilityType, year }
            });

            const transformedData = Object.entries(response?.data).flatMap(([name, value]) =>
                Object.entries(value).map(([key, val]) => ({
                    name: key,
                    value: parseFloat(val)  // Convert to number if necessary
                }))
            );

            setGraphs(transformedData);
            setGLoading(false)
        } catch (err) {
            setError(err.message);
        }
    };

    const fetchData = async () => {
        try {
            const response = await API.get('/assessment/filters', {
                params: { type: facilityType, year, district: district?.value, county: county?.value }
            });
            setData(response?.data);
            setColumns(getColumns(facilityType));  // Set columns based on facility type
        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        if (facilityType || year || district || county) {
            fetchData();
        }
    }, [facilityType, year, district, county]);

    useEffect(() => {
        API.get(`/assessment/districts?type=${facilityType}`)
            .then(response => {
                setDistricts(response.data.map(d => ({
                    value: d.district,
                    label: d.district
                })));
            })
            .catch(error => console.error(error));
    }, [facilityType]);

    useEffect(() => {
        if (district) {
            API.get(`/assessment/county?district=${district.value}`)
                .then(response => {
                    setSubCounties(response.data.map(c => ({
                        value: c.county,
                        label: c.county
                    })));
                })
                .catch(error => console.error(error));
        } else {
            setSubCounties([]);
        }
    }, [district]);

    useEffect(() => {
        if (county) {
            API.get(`/assessment/facilities?county=${county.value}`)
                .then(response => {
                    setFacilities(response.data.map(f => ({
                        value: f.facility,
                        label: f.facility
                    })));
                })
                .catch(error => console.error(error));
        } else {
            setFacilities([]);
        }
    }, [county]);

    const getColumns = (type) => {
        if (type === 'Dentistry') {
            return [
                'Date', 'District', 'County', 'Facility', 'Leadership', 'Human Resource',
                'Infrastructure', 'Promotion', 'Essential', 'Equipment', 'Clinical', 'Scores', 'Type'
            ];
        } else if (type === 'Radiology') {
            return [
                'Date', 'District', 'County', 'Facility', 'Leadership', 'Human Resource',
                'Infrastructure', 'Promotion', 'Essential', 'Equipment', 'Clinical', 'Radiation', 'Scores', 'Type'
            ];
        } else if (type === 'Laboratory') {
            return [
                'Facility', 'Leadership', 'Human Resource', 'Infrastructure', 'Promotion',
                'Essential', 'Equipment', 'Laboratory', 'Scores', 'Type'
            ];
        } else if (type === 'General Facility') {
            return [
                'Date', 'District', 'County', 'Facility', 'Leadership', 'Human Resource',
                'Occupational Safety', 'Infrastructure', 'Promotion', 'Essential', 'Maternity',
                'Theatre', 'Prevention', 'Client', 'Equipment', 'Laboratory', 'Medicines',
                'Records', 'Radiology', 'Mortuary', 'Scores', 'Type'
            ];
        }
        return [];
    };

    // if (graphs.length === 0) {
    //     return <div>No data available</div>;
    // }

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">SQIS+ - Facilities Assessment Performance</h4>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card job-filter">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-2">
                                    <div className="position-relative">
                                        <select className="form-select"
                                            value={year}
                                            onChange={(e) => setYear(e.target.value)}>
                                            <option value="">Select Year</option>
                                            <option value="2024">2024</option>
                                            <option value="2023">2023</option>
                                            <option value="2022">2022</option>
                                            <option value="2021">2021</option>
                                            <option value="2020">2020</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative">
                                        <select
                                            className="form-select"
                                            aria-label="Select example"
                                            value={facilityType}
                                            onChange={(e) => setFacilityType(e.target.value)}
                                        >
                                            <option value="">Select Facility Type</option>
                                            <option value="General Facility">General Facility</option>
                                            <option value="Dentistry">Dentistry</option>
                                            <option value="Laboratory">Laboratory</option>
                                            <option value="Radiology">Radiology</option>
                                            <option value="Pharmacy">Pharmacy</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative">
                                        <Select
                                            value={district}
                                            onChange={setDistrict}
                                            options={districts}
                                            placeholder="Select District"
                                            isClearable
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative">
                                        <Select
                                            value={county}
                                            onChange={setCounty}
                                            options={subCounties}
                                            placeholder="Sub-County"
                                            isClearable
                                            isDisabled={!district}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative">
                                        <Select
                                            value={facility}
                                            onChange={setFacility}
                                            options={facilities}
                                            placeholder="Select Facility"
                                            isClearable
                                            isDisabled={!county}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="position-relative h-100 hstack">
                                        <div className="flex-shrink-0">
                                            <a href="#!" onClick={clearFilters} className="btn btn-primary">
                                                <i className="mdi mdi-filter-outline align-middle"></i>
                                                Clear
                                            </a>
                                            <a href="#!" onClick={() => handleTabClick('data')}
                                                className="btn btn-warning mx-1"><i className="mdi mdi-refresh"></i> Data</a>
                                            <a href="#!"
                                                onClick={() => handleTabClick('graphs')}
                                                className="btn btn-success"><i className="bx bx-down-arrow-alt"></i> Graphs</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    {activeTab === 'data' ? (
                        <div>
                            {data.length > 0 && (
                                <div className="table-responsive">
                                    <table className="table align-middle table-striped table-sm">
                                        <thead className="table-light">
                                            <tr>
                                                {columns.map((col, index) => (
                                                    <th key={index}>{col}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    {columns.map((col, idx) => (
                                                        <td key={idx}>{item[col.toLowerCase()]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <Graphs data={graphs} loading={gLoading} type={facilityType} />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Performance;