import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
    { stage: 'Entered Training', midwives: 1500, nurses: 2000 },
    { stage: 'Graduated', midwives: 1200, nurses: 1800 },
    { stage: 'Obtained Registration', midwives: 1100, nurses: 1700 },
    { stage: 'Obtained License', midwives: 1000, nurses: 1600 },
];

const Training = () => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={600}
                height={300}
                data={data}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="stage" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="midwives" fill="#8884d8" barSize={40}/>
                <Bar dataKey="nurses" fill="#82ca9d" barSize={40}/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default Training;
