import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import TrendChart from './TrendChart';
import IssueDistribution from './IssueDistribution';
import Filters from './Filters';

const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [issues, setIssues] = useState([]);

    const loadTickets = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/t/tickets/count`);
            setCount(res?.data.statusCounts);
            setTotal(res?.data.results);
            setIssues(res?.data.tickets);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadTickets();
    }, []);

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Uganda National Stewardship Performance Dashboard</h4>
                    </div>
                </div>
            </div>
            <Filters />
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of functional facilities</p>
                                    <h4 class="mb-0">{total && total}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">% of facilities scoring star 2 and above</p>
                                    <h4 class="mb-0">{count && count.unassigned}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Registered health facilities</p>
                                    <h4 class="mb-0">{count && count.assigned}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of active Heath Workers</p>
                                    <h4 class="mb-0">{count && count.closed}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of Interns</p>
                                    <h4 class="mb-0">{count && count.inProgress}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="nav nav-tabs nav-tabs-custom" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" data-bs-toggle="tab" href="#all-order" role="tab" aria-selected="true">
                        All Orders
                    </a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" data-bs-toggle="tab" href="#processing" role="tab" aria-selected="false" tabindex="-1">
                        Processing
                    </a>
                </li>
            </ul>
            <div class="tab-content p-3">
                <div class="tab-pane active show" id="all-order" role="tabpanel">
                    {/* <div class="row">
                        <div class="col-lg-4">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Sales Trend Analysis</h4>
                                    <IssueDistribution data={issues} indicator='facilityLevel' />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Sales Vs Revenues</h4>
                                    <IssueDistribution data={issues} indicator="status" />
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Monthly Analysis</h4>
                                    <IssueDistribution data={issues} indicator="issueCategory" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div class="tab-pane" id="processing" role="tabpanel">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Issues trends over time created vs. closed</h4>
                                    {/* <TrendChart data={issues} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Dashboard