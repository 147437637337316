import React, { useState, useEffect, Fragment } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import API from '../../helpers/api';

const Authority = () => {
  const [loading, setLoading] = useState(false);
  const [authority, setAuthority] = useState([]);

  const loadAuthority = async () => {
    setLoading(true);
    try {
      const res = await API.get('/uhf/authority');
      setAuthority(res?.data);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAuthority();
  }, []);

  // Function to return color based on authority name
  const getColor = (authority) => {
    switch (authority) {
      case 'AHPC':
        return '#8884d8'; // Purple
      case 'UMDPC':
        return '#82ca9d'; // Green
      case 'UNMC':
        return '#ffc658'; // Yellow
      case 'Pharmacy Board':
        return '#d84d4d'; // Red
      default:
        return '#000000'; // Black
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Private Health Facilities Registered By Authority</h4>
              <div className="table-responsive">
                <table className="table align-middle table-striped table-sm">
                  <thead className="table-dark">
                    <tr>
                      <th>Authority</th>
                      <th>Count</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authority &&
                      authority.map((item) => (
                        <tr key={item.id}>
                          <td>{item.authority}</td>
                          <td>{item.count}</td>
                          <td>{item.percentage} %</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">% of Private Health Facilities Registered By Authority</h4>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={authority}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="authority" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="percentage" barSize={50}>
                    {authority.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getColor(entry.authority)} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Authority;
