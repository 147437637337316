/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import Filters from '../../components/FNFilters';
import Authority from './Authority';
import Ownership from './Ownership';
import Facilities from './Facilities';

const Sqis = () => {

    const [loading, setLoading] = useState(false);
    const [pfp, setPFP] = useState(0);
    const [pnfp, setPNFP] = useState(0);
    const [ahpc, setAHPC] = useState(0);
    const [unmc, setUNMC] = useState(0);
    const [unmdpc, setUNMDPC] = useState(0);
    const [activeTab, setActiveTab] = useState('authority');

    const loadStats = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/uhf`);
            setPFP(res?.data.pfp);
            setPNFP(res?.data.pnfp);
            setAHPC(res?.data.ahpc);
            setUNMC(res?.data.unmc);
            setUNMDPC(res?.data.unmdpc);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadStats();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'authority':
                return (
                    <Authority />
                );
            case 'ownership':
                return (
                    <Ownership />
                );
            case 'facilities':
                return (
                    <Facilities />
                );
            case 'deployed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Facilities Assessed By Type</h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'cadre':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% Performance of Facilities Assessed General </h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'training':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% Performance of Facilities Assessed General </h4>
                                    <Facilities />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Private Sector Support Supervision - SQIS+</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-primary">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Private For Profit Facilities</p>
                                    <h4 class="mb-0">{pfp && pfp}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Private Not For Profit Facilities</p>
                                    <h4 class="mb-0">{pnfp && pnfp}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number UNMC Registried Facilities</p>
                                    <h4 class="mb-0">{unmc && unmc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number AHPC Registried Facilities</p>
                                    <h4 class="mb-0">{ahpc && ahpc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total Number UNMDPC Facilities</p>
                                    <h4 class="mb-0">{unmdpc && unmdpc}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'authority' ? 'active' : ''}`}
                        onClick={() => setActiveTab('authority')}
                        role="tab"
                        aria-selected={activeTab === 'authority'}
                    >
                        Facilities By Authority
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'ownership' ? 'active' : ''}`}
                        onClick={() => setActiveTab('ownership')}
                        role="tab"
                        aria-selected={activeTab === 'ownership'}
                    >
                        Facilities By Ownership
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'facilities' ? 'active' : ''}`}
                        onClick={() => setActiveTab('facilities')}
                        role="tab"
                        aria-selected={activeTab === 'facilities'}
                    >
                        Facilities Assessed Per District
                    </a>
                </li>
                {/* <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'deployed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('deployed')}
                        role="tab"
                        aria-selected={activeTab === 'deployed'}
                    >
                        Facility Score Per Module
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'cadre' ? 'active' : ''}`}
                        onClick={() => setActiveTab('cadre')}
                        role="tab"
                        aria-selected={activeTab === 'cadre'}
                    >
                        Facility Score Per Module
                    </a>
                </li> */}
            </ul>
            <div className="tab-content p-3">
                <div className={`tab-pane ${activeTab === 'authority' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'authority' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'ownership' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'ownership' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'facilities' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'facilities' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'deployed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'deployed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'cadre' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'cadre' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'training' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'training' && renderContent()}
                </div>
            </div>
        </Fragment>
    )
}

export default Sqis