import React from 'react';
import { MapContainer, TileLayer, CircleMarker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const data = [
  {
    region: 'Central',
    midwives: 800,
    nurses: 1000,
    gap: 200,
    coordinates: [0.3476, 32.5825], // Coordinates for Kampala
  },
  {
    region: 'Northern',
    midwives: 600,
    nurses: 850,
    gap: 250,
    coordinates: [2.7724, 32.2881], // Coordinates for Gulu
  },
  {
    region: 'Eastern',
    midwives: 700,
    nurses: 900,
    gap: 200,
    coordinates: [1.4845, 32.4083], // Coordinates for Mbale
  },
  {
    region: 'Western',
    midwives: 650,
    nurses: 800,
    gap: 150,
    coordinates: [-0.6008, 30.6485], // Coordinates for Mbarara
  },
];

const Deployment = () => {
  return (
    <MapContainer center={[1.3733, 32.2903]} zoom={7} style={{ height: '100vh', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {data.map((regionData, index) => (
        <CircleMarker
          key={index}
          center={regionData.coordinates}
          radius={10 + regionData.gap / 10}
          fillColor="red"
          color="red"
          weight={1}
          opacity={0.5}
          fillOpacity={0.5}
        >
          <Popup>
            <strong>{regionData.region}</strong><br />
            Midwives: {regionData.midwives}<br />
            Nurses: {regionData.nurses}<br />
            Gap: {regionData.gap}
          </Popup>
        </CircleMarker>
      ))}
    </MapContainer>
  );
};

export default Deployment;
