import React, { useState } from 'react';
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import API from "../../helpers/api";
// import healthFacilities from "../../data/facilities";

const Filters = ({ setFilteredData, fetchData, downloadExcel }) => {

    const [level, setLevel] = useState("");
    const [facility, setFacility] = useState("");
    const [status, setStatus] = useState("");
    const [system, setSystem] = useState("");
    const [category, setCategory] = useState("");
    const [loading, setLoading] = useState(false);

    // const filteredHealthFacilities = healthFacilities.filter(
    //     (facility) => facility.level === level
    // );

    const handleSelectChange = (selectedOption) => setFacility(selectedOption);

    const handleFilterChange = async () => {
        setLoading(true);
        try {
            const response = await API.get('/t/tickets', {
                params: {
                    category,
                    status,
                    system,
                    level,
                    facility: facility ? facility.value : '',
                },
            });
            setFilteredData(response.data.tickets);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div class="row">
            <div class="col-lg-12">
                <div class="card job-filter">
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-2">
                                <div class="position-relative">
                                    <select class="form-select"
                                        value={category}
                                        onChange={(e) => setCategory(e.target.value)}>
                                        <option value="">From Year</option>
                                        <option value="Software Glicthes">Software Glicthes</option>
                                        <option value="Hardware">Hardware</option>
                                        <option value="Change Request">Change Request</option>
                                        <option value="Network">Network</option>
                                        <option value="Account Access">Account Access</option>
                                        <option value="Application Support">Application Support</option>
                                        <option value="Data Loss">Data Loss and Backup</option>
                                        <option value="Performance">Performance Issues</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <select class="form-select"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">To Year</option>
                                        <option value="unassigned">Unassigned</option>
                                        <option value="inprogress">In Progress</option>
                                        <option value="Closed">Closed</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <div id="datepicker1">
                                        <select class="form-select"
                                            value={system}
                                            onChange={(e) => setSystem(e.target.value)}>
                                            <option value="">Select District</option>
                                            <option value="EMR">EMR</option>
                                            <option value="eCHIS">eCHIS</option>
                                            <option value="DHIS2">DHIS2</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <select
                                        className="form-select"
                                        aria-label="Select example"
                                        value={level}
                                        onChange={(e) => setLevel(e.target.value)}
                                    >
                                        <option value="">Select Facility Level</option>
                                        <option value="National Referral">
                                            National Referral
                                        </option>
                                        <option value="Regional Referral">
                                            Regional Referral
                                        </option>
                                        <option value="General Hospital">
                                            General Hospital
                                        </option>
                                        <option value="HC IV">HC IV</option>
                                        <option value="HC III">HC III</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative">
                                    <Select
                                        // options={filteredHealthFacilities}
                                        value={facility}
                                        onChange={handleSelectChange}
                                        placeholder="Select Facility"
                                        isSearchable
                                    />
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="position-relative h-100 hstack gap-3">
                                    <div class="flex-shrink-0">
                                        <a href="#!" onClick={handleFilterChange} class="btn btn-primary">
                                            <i class="mdi mdi-filter-outline align-middle"></i>Filter</a>
                                        <a href="#!" onClick={fetchData} class="btn btn-light mx-2"><i class="mdi mdi-refresh"></i> Clear</a>
                                        {/* <a href="#!" onClick={downloadExcel} class="btn btn-success"><i class="bx bx-down-arrow-alt"></i> </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filters