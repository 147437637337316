import React, { useState, useEffect, Fragment } from 'react';
import API from "../../../helpers/api";
import Filters from '../../../components/FNFilters';

const FacilityPerformance = () => {
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/hfqap//facility/performance`);
            console.log("response ==>", res)
            setScores(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const getColorByScore = (score) => {
        if (score < 50) return 'red';
        if (score >= 50 && score < 80) return 'yellow';
        return 'green';
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">District Health Facility  Performance Scorecard Dashboard</h4>
                    </div>
                </div>
            </div>
            <Filters />
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead class="table-light">
                                    <tr>
                                        <th>Facility</th>
                                        <th>Leadership</th>
                                        <th>Human Resources</th>
                                        <th>Health Financing</th>
                                        <th>Health Information</th>
                                        <th>Medcines</th>
                                        <th>Health Infrastucture</th>
                                        <th>RMNCAH</th>
                                        <th>Clinical Services</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scores.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.facility}</td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.leadership_governance)) }}>
                                                {row.leadership_governance}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.human_resources_health)) }}>
                                                {row.human_resources_health}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_financing)) }}>
                                                {row.health_financing}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_information)) }}>
                                                {row.health_information}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.medicines_vaccines)) }}>
                                                {row.medicines_vaccines}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_infrastucture)) }}>
                                                {row.health_infrastucture}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.rmncah)) }}>
                                                {row.rmncah}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.clinical)) }}>
                                                {row.clinical}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FacilityPerformance