import React, { useState, useEffect, Fragment } from 'react';
import API from "../../../helpers/api";
import Filters from '../../../components/FNFilters';

const ModularScores = () => {
    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/hfqap/module/scores`);
            console.log("response ==>", res)
            setScores(res?.data || []);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const getColorByScore = (score) => {
        if (score < 50) return 'red';
        if (score >= 50 && score < 80) return 'yellow';
        return 'green';
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">National Performance Scorecard Dashboard</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {/* {scores.map((score) => (
                            <div className="col-2" key={score.module}>
                                <div className={`card mini-stats-wid ${getCardClass(score.module)}`}>
                                    <div className="card-body text-center">
                                        <h5 className="text-muted fw-medium">{`${score.module}`}</h5>
                                        <h6 className="m-0 font10">
                                            <strong data-plugin="counterup">{`${score.percentage_true_score} (%) `}</strong>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                        <div class="table-responsive">
                            <table class="table table-sm">
                                <thead class="table-light">
                                    <tr>
                                        <th>District</th>
                                        <th>Leadership</th>
                                        <th>Human Resources</th>
                                        <th>Health Financing</th>
                                        <th>Health Information</th>
                                        <th>Medcines</th>
                                        <th>Health Infrastucture</th>
                                        <th>RMNCAH</th>
                                        <th>Clinical Services</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scores.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.district}</td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.leadership_governance)) }}>
                                                {row.leadership_governance}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.human_resources_health)) }}>
                                                {row.human_resources_health}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_financing)) }}>
                                                {row.health_financing}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_information)) }}>
                                                {row.health_information}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.medicines_vaccines)) }}>
                                                {row.medicines_vaccines}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.health_infrastucture)) }}>
                                                {row.health_infrastucture}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.rmncah)) }}>
                                                {row.rmncah}%
                                            </td>
                                            <td style={{ backgroundColor: getColorByScore(parseFloat(row.clinical)) }}>
                                                {row.clinical}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

// Function to determine the card class based on the module name
const getCardClass = (moduleName) => {
    switch (moduleName) {
        case 'Module 1':
            return 'widget-three';
        case 'Module 2':
            return 'widget-two-info';
        case 'Module 3':
            return 'widget-two-warning';
        case 'Module 4':
            return 'widget-two-primary';
        case 'Module 5':
            return 'widget-two-success';
        case 'Module 6':
            return 'widget-all';
        case 'Module 7':
            return 'widget-three';
        case 'Module 8':
            return 'widget-two-info';
        case 'Module 9':
            return 'widget-two-warning';
        case 'Module 10':
            return 'widget-two-primary';
        case 'Module 5b':
            return 'widget-two-success';
        case 'Module 5c':
            return 'widget-all';
        case 'Module 5a':
            return 'widget-three';
        default:
            return '';
    }
};

export default ModularScores;
