/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import Filters from '../../components/FNFilters';
import FTable from './FTable';

const RawData = () => {

    const [loading, setLoading] = useState(false);
    const [raw, setRaw] = useState([]);

    const loadData = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/hfqap`);
            setRaw(res?.data);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">HFQAP - Raw Data Set</h4>
                    </div>
                </div>
            </div>
            <Filters />
            <div class="card">
                <div class="card-body">
                    <FTable data={raw} />
                </div>
            </div>
        </Fragment >
    )
}

export default RawData