import React from 'react'
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const Graphs = ({ data, loading, type }) => {

    console.log("Graphs ===>", data)

    const CustomizedLabel = ({ x, y, value }) => {
        return (
            <text x={x} y={y} dy={-10} fill="#000" textAnchor="middle" fontSize="14" dominantBaseline="middle">
                {value}%
            </text>
        );
    };

    // const sortedData = [...data].sort((a, b) => b.value - a.value);

    return (
        <div>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <h4 className="card-title mb-4">Average Performance of Facilities Assessed - {type}</h4>
                    <ResponsiveContainer width="100%" height={350}>
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" label={{ value: '', position: 'insideBottomRight', offset: 0 }} />
                            <YAxis label={{ value: 'Average Score', angle: -90, position: 'insideLeft' }} />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="value" fill="#8884d8" barSize={80}>
                                <LabelList content={<CustomizedLabel />} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </>
            )}
        </div>
    )
}

export default Graphs