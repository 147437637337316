import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import API from "../../helpers/api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CoverageDistrict = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const coverageDistrict = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/hfqap/coverage/district`);
            console.log("coverage ===>", res)
            setData(res.data)
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        coverageDistrict();
    }, []);

    if (loading) {
        return <Spinner />
    }

    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="4 4" />
                <XAxis dataKey="district" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="assessed" fill="#8884d8" />
                {/* <Bar dataKey="Enrolled Comprehensive Nurse" fill="#8884d8">
                    <LabelList dataKey="Enrolled Comprehensive Nurse" position="top" />
                </Bar>
                <Bar dataKey="Enrolled Midwife" fill="#82ca9d">
                    <LabelList dataKey="Enrolled Midwife" position="top" />
                </Bar>
                <Bar dataKey="Enrolled Nurse" fill="#ffc658">
                    <LabelList dataKey="Enrolled Nurse" position="top" />
                </Bar> */}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default CoverageDistrict