import React from 'react'

const FTable = ({ data }) => {

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    return (
        <div class="table-responsive">
            <table class="table align-middle table-striped table-sm">
                <thead class="table-light">
                    <tr>
                        <th>Health Facility</th>
                        <th>Assessment Date</th>
                        <th>Module</th>
                        <th>Assessment Name</th>
                        <th>Score</th>
                        <th className="align-middle">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index}>
                            <td>{row.facility}</td>
                            <td>{new Date(row.assessment_date).toLocaleDateString()}</td>
                            <td>{row.module}</td>
                            <td>{truncateText(row.assessment_name, 60)}</td>
                            <td>{row.score}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default FTable