/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../helpers/api";
import Filters from '../../components/FNFilters';
import Gender from './Gender';

const Training = () => {

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [total, setTotal] = useState(0);
    const [training, setTraining] = useState(0);
    const [providers, setProviders] = useState(0);
    const [facilities, setFacilities] = useState(0);
    const [activeTab, setActiveTab] = useState('enrolled');
    const [percentage, setPercentage] = useState();

    const loadStats = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/ihris/total`);
            setTotal(res?.data.total);
            setTraining(res?.data.trainings);
            setProviders(res?.data.providers);
            setFacilities(res?.data.facilities);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    

    useEffect(() => {
        loadStats();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'enrolled':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Workers enrolled in training programs</h4>
                                    <Gender />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'registered':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Workers completed professional development</h4>
                                    {/* <Gender /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'licensed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Workers receiving in-service training</h4>
                                    {/* <Gender /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'deployed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Training Institutions accredited </h4>
                                    {/* <Gender /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'cadre':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Health Training Institutions accredited</h4>
                                    {/* <Gender /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'training':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Nurses and Midwives who entered training</h4>
                                    {/* <Gender /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Human Resource Development Dashboard</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of Health Workers Trained</p>
                                    <h4 class="mb-0">{total && total}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-primary">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of Trainings Conducted</p>
                                    <h4 class="mb-0">{training && training}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Number of Training Providers</p>
                                    <h4 class="mb-0">{providers && providers}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Training Programs Conducted</p>
                                    <h4 class="mb-0">{count && facilities}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of training sites</p>
                                    <h4 class="mb-0">{facilities && facilities}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'enrolled' ? 'active' : ''}`}
                        onClick={() => setActiveTab('enrolled')}
                        role="tab"
                        aria-selected={activeTab === 'enrolled'}
                    >
                        Training Programs
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'registered' ? 'active' : ''}`}
                        onClick={() => setActiveTab('registered')}
                        role="tab"
                        aria-selected={activeTab === 'registered'}
                    >
                        Professional Development
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'licensed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('licensed')}
                        role="tab"
                        aria-selected={activeTab === 'licensed'}
                    >
                        In-Service Training
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'deployed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('deployed')}
                        role="tab"
                        aria-selected={activeTab === 'deployed'}
                    >
                        Deployment
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'cadre' ? 'active' : ''}`}
                        onClick={() => setActiveTab('cadre')}
                        role="tab"
                        aria-selected={activeTab === 'cadre'}
                    >
                        Training Facilities
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{ cursor: 'pointer' }}>
                    <a
                        className={`nav-link ${activeTab === 'training' ? 'active' : ''}`}
                        onClick={() => setActiveTab('training')}
                        role="tab"
                        aria-selected={activeTab === 'training'}
                    >
                        Training
                    </a>
                </li>
            </ul>
            <div className="tab-content p-3">
                <div className={`tab-pane ${activeTab === 'enrolled' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'enrolled' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'registered' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'registered' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'licensed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'licensed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'deployed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'deployed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'cadre' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'cadre' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'training' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'training' && renderContent()}
                </div>
            </div>
        </Fragment>
    )
}

export default Training