import React, { useState, useEffect } from 'react'
import API from "../../../helpers/api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer } from 'recharts';

const Cadre = () => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadCadre = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/nurses/cadre`)
      setData(res?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCadre();
  }, []);

  const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57'];

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!data.length) {
    return <p>No data available</p>;
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="cadre_name" tick={{ angle: -10, textAnchor: 'end' }} />
        <YAxis domain={[0, 100]} />
        <Tooltip />
        <Legend />
        <Bar dataKey="percentage" fill="#8884d8">
          <LabelList dataKey="percentage" position="top" formatter={(value) => `${value}%`} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Cadre