/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react'
import API from "../../../helpers/api";
import Filters from '../../../components/FNFilters';
import Enrollment from './enrollment';
import Cadre from './Cadre';
import Training from './Training';
import Deployment from './Deployment';
import Registered from './Registered';
import Licensed from './Licensed';

const Unmc = () => {

    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [enrolled, setEnrolled] = useState(0);
    const [registered, setRegistered] = useState(0);
    const [licensed, setLicensed] = useState(0);
    const [training, setTraining] = useState(0);
    const [activeTab, setActiveTab] = useState('enrolled');
    const [percentage, setPercentage] = useState();

    const loadStats = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/nurses/total`);
            setEnrolled(res?.data.enrolled);
            setRegistered(res?.data.registered);
            setLicensed(res?.data.licensed);
            setTraining(res?.data.training);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    

    useEffect(() => {
        loadStats();
    }, []);

    const renderContent = () => {
        switch (activeTab) {
            case 'enrolled':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Enrolled Midwives & Nurses By Cadre</h4>
                                    <Enrollment />
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">Enrollment Rate of Midwives & Nurses</h4>
                                    <EnrollmentRate />
                                </div>
                            </div>
                        </div> */}
                    </div>
                );
            case 'registered':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Registered Midwives & Nurses</h4>
                                    <Registered />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'licensed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Licensed Health Workers By Gender</h4>
                                    <Licensed />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'deployed':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Deployed Midwives & Nurses</h4>
                                    <Deployment />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'cadre':
                return (
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title mb-4">% of Registered Health Workers by Cadre</h4>
                                    <Cadre />
                                </div>
                            </div>
                        </div>
                    </div>
                );
                case 'training':
                    return (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Nurses and Midwives who entered training</h4>
                                        <Training />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div class="row">
                <div class="col-12">
                    <div class="mb-4 d-sm-flex align-items-center justify-content-between">
                        <h4 class="mb-sm-0 font-size-18">Uganda Nurses and Midwives Council Dashboard</h4>
                    </div>
                </div>
            </div>
            {/* <Filters /> */}
            <div class="row">
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-success">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of enrolled midwives & nurses</p>
                                    <h4 class="mb-0">{enrolled && enrolled}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-primary">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of registered midwives & nurses</p>
                                    <h4 class="mb-0">{registered && registered}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                        <span class="avatar-title">
                                            <i class="bx bx-copy-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-warning">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of licensed midwives & nurses</p>
                                    <h4 class="mb-0">{licensed && licensed}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-archive-in font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-two-info">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of nurses training institutions</p>
                                    <h4 class="mb-0">{training && training}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md">
                    <div class="card mini-stats-wid widget-three">
                        <div class="card-body">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="text-muted fw-medium">Total number of licensed private facilities</p>
                                    <h4 class="mb-0">{count && count.inProgress}</h4>
                                </div>
                                <div class="flex-shrink-0 align-self-center">
                                    <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                        <span class="avatar-title rounded-circle bg-primary">
                                            <i class="bx bx-purchase-tag-alt font-size-24"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'enrolled' ? 'active' : ''}`}
                        onClick={() => setActiveTab('enrolled')}
                        role="tab"
                        aria-selected={activeTab === 'enrolled'}
                    >
                        Enrollment
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'registered' ? 'active' : ''}`}
                        onClick={() => setActiveTab('registered')}
                        role="tab"
                        aria-selected={activeTab === 'registered'}
                    >
                        Registration
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'licensed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('licensed')}
                        role="tab"
                        aria-selected={activeTab === 'licensed'}
                    >
                        Licensed
                    </a>
                </li>
                {/* <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'deployed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('deployed')}
                        role="tab"
                        aria-selected={activeTab === 'deployed'}
                    >
                        Deployment
                    </a>
                </li> */}
                <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'cadre' ? 'active' : ''}`}
                        onClick={() => setActiveTab('cadre')}
                        role="tab"
                        aria-selected={activeTab === 'cadre'}
                    >
                        Cadre
                    </a>
                </li>
                <li className="nav-item" role="presentation" style={{cursor: 'pointer'}}>
                    <a
                        className={`nav-link ${activeTab === 'training' ? 'active' : ''}`}
                        onClick={() => setActiveTab('training')}
                        role="tab"
                        aria-selected={activeTab === 'training'}
                    >
                        Training
                    </a>
                </li>
            </ul>
            <div className="tab-content p-3">
                <div className={`tab-pane ${activeTab === 'enrolled' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'enrolled' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'registered' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'registered' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'licensed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'licensed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'deployed' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'deployed' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'cadre' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'cadre' && renderContent()}
                </div>
                <div className={`tab-pane ${activeTab === 'training' ? 'active show' : ''}`} role="tabpanel">
                    {activeTab === 'training' && renderContent()}
                </div>
            </div>
        </Fragment >
    )
}

export default Unmc