import React, { useState, useEffect, Fragment } from 'react';
import API from '../../helpers/api';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

const Facilities = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA46BE', '#EF7674'];

  const loadFacilities = async () => {
    setLoading(true);
    try {
      const res = await API.get('/uhf/district');
      setFacilities(res?.data);
      setData(res?.data.map((item) => ({ name: item.facilities, value: item.count })));
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFacilities();
  }, []);

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Facilities Assessed Per District</h4>
              <div className="table-responsive">
                <table className="table align-middle table-striped table-sm">
                  <thead className="table-dark">
                    <tr>
                      <th>District</th>
                      <th>Facilities Assessed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {facilities &&
                      facilities.map((item) => (
                        <tr key={item.id}>
                          <td>{item.District}</td>
                          <td>{item.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">% of Health Facilities Assessed By District</h4>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      data={data}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, value }) => `${name}: ${value}`}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Facilities;