import React, { useState, useEffect, Fragment } from 'react';
import API from '../../helpers/api';
import { Spinner } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const Ownership = () => {
  const [loading, setLoading] = useState(false);
  const [ownership, setOwnership] = useState([]);

  const loadOwership = async () => {
    setLoading(true);
    try {
      const res = await API.get('/uhf/ownership');
      setOwnership(res?.data);
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const getColor = (ownership) => {
    switch (ownership) {
      case 'PFP':
        return '#8884d8'; // Purple
      case 'PNFP':
        return '#82ca9d'; // Green
      case 'PHP':
        return '#ffc658'; // Yellow
      default:
        return '#000000'; // Black
    }
  };

  useEffect(() => {
    loadOwership();
  }, []);


  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Private Health Facilities By Ownership</h4>
              <div className="table-responsive">
                <table className="table align-middle table-striped table-sm">
                  <thead className="table-dark">
                    <tr>
                      <th>Ownership</th>
                      <th>Count</th>
                      <th>Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ownership &&
                      ownership.map((item) => (
                        <tr key={item.id}>
                          <td>{item.ownership}</td>
                          <td>{item.count}</td>
                          <td>{item.percentage} %</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">% of Private Health Facilities By Ownership</h4>
              {loading ? (
                <Spinner />
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={ownership}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="ownership" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="percentage" barSize={50}>
                      {ownership.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={getColor(entry.ownership)} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Ownership;