import React, { useState, useEffect } from 'react'
import API from "../../helpers/api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Gender = () => {
    const [percentage, setPercentage] = useState();
    const [loading, setLoading] = useState(false);

    const loadScore = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/ihris/percentage`);
            console.log("Score ===>", res)
            setPercentage(res?.data.percentages);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const transformedData = percentage && percentage.reduce((acc, item) => {
        const existing = acc.find(d => d.year === item.year);
        if (existing) {
            existing[item.gender] = item.percentage;
        } else {
            acc.push({
                year: item.year,
                [item.gender]: item.percentage
            });
        }
        return acc;
    }, []);

    useEffect(() => {
        loadScore();
    }, []);

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                width={600}
                height={300}
                data={transformedData}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="F" fill="#8884d8" name="Female" />
                <Bar dataKey="M" fill="#82ca9d" name="Male" />
            </BarChart>
        </ResponsiveContainer>
    )
}

export default Gender