import React, { Fragment } from 'react'
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from './components/Layout';
import Login from './pages/Auth/Login';

import Dashboard from './pages/Dashboard';
import Hfqap from './pages/Hfqap';
import Sqis from './pages/Sqis';
import Training from './pages/Training';
import Unmc from './pages/Councils/Unmc';
import Allied from './pages/Councils/Allied';
import Medical from './pages/Councils/Medical';
import RawData from './pages/Hfqap/RawData';
import ModularScores from './pages/Hfqap/ModularScores';
import Performance from './pages/Sqis/Performance';
import FacilityPerformance from './pages/Hfqap/FacilityPerformance';

const App = () => {
  return (
    <Fragment>
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Login} />
        <Layout>
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/hfqap" component={Hfqap} />
          <Route exact path="/hfqap/dataset" component={RawData} />
          <Route exact path="/sqis" component={Sqis} />
          <Route exact path="/sqis/performance" component={Performance} />
          <Route exact path="/training" component={Training} />
          <Route exact path="/council/unmc" component={Unmc} />
          <Route exact path="/council/medical" component={Allied} />
          <Route exact path="/council/allied" component={Medical} />
          <Route exact path="/hfqap/module/scores" component={ModularScores} />
          <Route exact path="/hfqap/performance" component={FacilityPerformance} />
        </Layout>
      </Switch>
    </Fragment>
  )
}

export default App