import React, { useState, useEffect } from 'react'
import API from "../../../helpers/api";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,ResponsiveContainer } from 'recharts';

const Registered = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const loadEnrolled = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/nurses/registered`);

            const transformedData = res.data.reduce((acc, item) => {
                const { year, cadre_name, percentage } = item;
                let yearData = acc.find(d => d.year === year);
                if (!yearData) {
                    yearData = { year };
                    acc.push(yearData);
                }
                yearData[cadre_name] = percentage;
                return acc;
            }, []);

            setData(transformedData);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEnrolled();
    }, []);

    return (
        <ResponsiveContainer width="100%" height={500}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="Registered Nurse" fill="#8884d8">
                    <LabelList dataKey="Registered Nurse" position="top" />
                </Bar>
                <Bar dataKey="Registered Comprehensive Nurse" fill="#82ca9d">
                    <LabelList dataKey="Registered Comprehensive Nurse" position="top" />
                </Bar>
                <Bar dataKey="Registered Midwife" fill="#ffc658">
                    <LabelList dataKey="Registered Midwife" position="top" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default Registered